import styled, { css } from 'styled-components'
import { ifProp, prop } from 'styled-tools'
import Button from '../../components/Button'
import { PopupLite } from '../../components/Popup/Popup'

import Deactivate from '../../components/Icons/Deactivate'
import { CustomInputs } from '../../components/FormFields'
import Input from '../../components/Input'
import InfoIcon from '../../components/Icons/InfoIcon'
import colorsOptions, {
  applyOpacity,
  pinsColors
} from '../../components/stories/fundamentals/colors-styles/colors'
import {
  sizing,
  weights
} from '../../components/stories/fundamentals/text-styles/text-styles'

export const FormContainer = styled.div`
  margin: 0 0 8px;
  width: 100%;
  border: 1px solid ${colorsOptions.greys['grey-20']};
  transition: border-color 1s;
  padding: 10px 16px;
  border-radius: 8px;
  background: ${colorsOptions.greys.white};
  &:after {
    content: '.';
    display: block;
    clear: both;
    visibility: hidden;
    line-height: 0;
    height: 0;
  }
`
export const FormControl = styled.div`
  margin-bottom: 10px;
`

export const DatePickerControl = styled.div`
  margin-bottom: 10px;
  display: inline-block;

  &:not(:first-child) {
    margin-left: 8px;
  }

  .react-datepicker__input-container {
    display: block;
  }
`

export const Label = styled.label`
  margin-bottom: 5px;
  width: 100%;
  display: block;
`

export const Sectionlabel = styled.div`
  ${weights['text-700']};
  ${sizing['text-l']};
  margin-bottom: 16px;
  display: block;
`
export const ButtonBlock = styled.div`
  text-align: right;
  margin-top: 24px;
  clear: both;
  overflow: auto;
  @media (max-width: 767px) {
    text-align: left;
  }
`
export const MessageBlock = styled.div`
  float: left;
  margin-top: 7px;
  width: 39%;
  text-align: left;
  @media (max-width: 767px) {
    float: none;
    width: 100%;
    text-align: center;
    margin-bottom: 7px;
  }
`

export const Changebtn = styled(Button)`
  padding: 8px 37px;
  display: inline-block;
  color: ${colorsOptions.greys['grey-100']};
  text-align: center;
  ${sizing['text-l']};
  border-radius: 40px;
  border: 1px solid ${colorsOptions.greys['grey-20']};

  background: ${colorsOptions.greys.white};
  ${weights['text-600']};
`

export const Cancel = styled(Button)`
  padding: 8px 37px;
  display: inline-block;
  color: ${colorsOptions.greys['grey-60']};
  text-align: center;
  ${sizing['text-l']};
  border-radius: 40px;
  border: 1px solid ${colorsOptions.greys['grey-20']};

  background: ${colorsOptions.greys.white};
  ${weights['text-600']};
  margin-right: 10px;

  &:hover {
    background: ${colorsOptions.greys['grey-20']};
    border-color: ${colorsOptions.greys['grey-30']};
    cursor: pointer;
  }
  &:disabled {
    background: none !important;
    opacity: 0.6;
    color: ${colorsOptions.greys['grey-50']};
    border: 1px solid ${colorsOptions.greys['grey-20']};
    cursor: not-allowed;
  }
  .active_btn {
    width: auto;
    max-width: none;
    padding: 7px 27.5px;
  }
  @media (max-width: 767px) {
    padding: 7px 0;
    display: inline-block;
    width: 45%;
    max-width: 137px;
  }
`

export const Savebtn = styled(Button)`
  padding: 8px 37px;
  display: inline-block;
  color: ${colorsOptions.greys['grey-100']};
  text-align: center;
  ${sizing['text-l']};
  border-radius: 40px;
  border: 1px solid ${colorsOptions.greys['grey-20']};

  background: ${colorsOptions.greys.white};
  ${weights['text-600']};

  &:hover {
    background: ${colorsOptions.greys['grey-20']};
    border-color: ${colorsOptions.greys['grey-30']};
    cursor: pointer;
  }
  &:disabled {
    background: none;
    opacity: 0.6;
    color: ${colorsOptions.greys['grey-50']};
    border: 1px solid ${colorsOptions.greys['grey-20']};
    cursor: not-allowed;
  }
  @media (max-width: 767px) {
    padding: 7px 0;
    display: inline-block;
    width: 45%;
    max-width: 137px;
  }
`

export const Activebtn = styled(Button)`
  padding: 8px 37px;
  display: inline-block;
  color: ${colorsOptions.greys['grey-100']};
  text-align: center;
  ${sizing['text-l']};
  border-radius: 40px;
  border: 1px solid ${colorsOptions.greys['grey-20']};

  background: ${colorsOptions.greys.white};
  ${weights['text-600']};

  &:hover {
    background: ${colorsOptions.greys['grey-20']};
    border-color: ${colorsOptions.greys['grey-30']};
    cursor: pointer;
  }
  &:disabled {
    background: none !important;
    opacity: 0.6;
    color: ${colorsOptions.greys['grey-50']};
    border: 1px solid ${colorsOptions.greys['grey-20']};
    cursor: not-allowed;
  }
  @media (max-width: 767px) {
    padding: 7px 36.5px;
    display: inline-block;
  }
`

export const DropZoneStyle = styled.div`
  div {
    position: static !important;
    border-width: 0 !important;
    width: auto !important;
    height: auto !important;
    text-align: center !important;
    cursor: pointer !important;
  }
`

export const DropProjectImageBox = styled.div`
  border: ${ifProp('editPermission', 'dashed 2px #DBDBE3', 'none')};
  border-radius: 8px;
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  @media (max-width: 767px) {
    width: 60%;
    margin-top: 0;
  }
`

export const DropImageBox = styled.div`
  width: 80%;
  margin-top: 15px;
  @media (max-width: 767px) {
    width: 60%;
    margin-top: 0;
  }
`

export const AspectRatioDiv = styled.div`
  height: 100%;
  padding-right: 100%;
  //width: 100%;
  //padding-top: 100%;
  position: relative;
`

export const ProjectImageWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  padding: 4px;
  background: ${colorsOptions.greys.white};
  border-radius: 8px;
`

export const ProjectImg = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 8px;
  display: inline-block;
  overflow: hidden;
  object-fit: cover;
`

export const OverlayDeleteImg = styled.span`
  all: unset;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 8px;
  background-color: ${colorsOptions.greys['grey-90']};
  opacity: 0;
  transition: background-color 0.3s ease, opacity 0.3s ease; 
  display: flex;
  justify-content: center;
  align-items: center;

  ${DropZoneStyle}:hover & {
    background-color: ${applyOpacity(
      colorsOptions.greys['grey-90'],
      0.5
    )}; 
    opacity: 1; 
  }
`

export const DeleteButton = styled.button`
  all: unset;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 16px;
  img {
    opacity: 0.8;
    width: 35px;
    height: 35px;
    &:active {
      transition: width 500ms ease-out;
      width: 43px;
      height: 43px;
    }
  }
`

export const ProjectInsideContainer = styled.div`
  overflow: auto;
  border: ${({ editPermission }) =>
    editPermission ? 'dashed 2px #DBDBE3' : 'none'};
  padding: 8px;
  height: 80px;
  @media (max-width: 767px) {
    padding: 0;
    border: none;
  }
`

export const Uploadtext = styled.div`
  color: ${({ red }) =>
    red
      ? colorsOptions.secondaryColors.red.strong
      : colorsOptions.greys['grey-60']};
  ${weights['text-500']};
  ${sizing['text-r']};
`

export const InsideSpantext = styled.span`
  color: ${colorsOptions.greys['grey-60']};
  ${weights['text-500']};
  ${sizing['text-r']};
  @media (max-width: 767px) {
    display: none;
  }
`

export const Selectlink = styled.span`
  color: ${colorsOptions.secondaryColors.orange.normal};
  ${weights['text-600']};
  @media (max-width: 767px) {
    color: ${colorsOptions.greys['grey-100']};
    padding: 6px 0;
    display: inline-block;
    text-align: center;
    ${sizing['text-l']};
    border-radius: 40px;
    border: 1px solid ${colorsOptions.greys['grey-20']};

    background: ${colorsOptions.greys.white};
    margin-bottom: 7px;
    width: 100%;
    max-width: 200px;
  }
  &:hover {
    text-decoration: underline;
  }
`

export const Customlink = styled.label`
  color: ${colorsOptions.greys['grey-100']};
  ${weights['text-600']};
`

export const CustomLabel = styled.label`
  color: ${colorsOptions.greys['grey-100']};
  cursor: pointer;
  ${weights['text-600']};
`

export const InsideBlock = styled.div`
  padding: 15px;
  display: block;
`

export const QrCode = styled.img`
  margin: 15px auto;
  display: block;
`

export const Errormsg = styled.span`
  color: ${colorsOptions.secondaryColors.orange.strong};
  ${sizing['text-s']};
  ${ifProp('isTitleWarning', weights['text-500'], weights['text-600'])};
  @media (max-width: 1024px) {
    ${sizing['text-s']};
  }
`

export const Labelmsg = styled.span`
  ${sizing['text-r']};
  ${weights['text-600']};
  color: ${colorsOptions.greys['grey-50']};
  margin-left: ${prop('marginLeft', 0)}px;

  @media (max-width: 1024px) {
    ${sizing['text-s']};
  }
`

export const ServerErrorBlock = styled.div`
  color: ${colorsOptions.secondaryColors.orange.strong};
  ${sizing['text-r']};
  ${weights['text-600']};
  @media (max-width: 1024px) {
    ${sizing['text-s']};
  }
  text-align: center;
`

export const H1 = styled.h1`
  ${sizing['text-l']};
  ${weights['text-600']};
  background: #f5f5f9;
  padding: 10px;
  margin: 0;
`

export const Info = styled.span`
  opacity: ${({ visible }) => (visible ? '1' : '0')};
  transition: opacity 1s;
  ${sizing['text-r']};
  ${weights['text-600']};
  color: ${colorsOptions.greys['grey-50']};
  @media (max-width: 1024px) {
    ${sizing['text-s']};
  }
  color: ${colorsOptions.secondaryColors.orange.strong};
`

export const InfoTwo = styled.span`
  display: ${({ visible }) => (visible ? 'inline-block' : 'none')};
  transition: opacity 1s;
  ${sizing['text-r']};
  ${weights['text-600']};
  color: ${colorsOptions.greys['grey-50']};
  @media (max-width: 1024px) {
    ${sizing['text-s']};
  }
  color: ${colorsOptions.secondaryColors.orange.strong};
`

export const Category = styled.div`
  border-bottom: 1px solid ${colorsOptions.greys['grey-20']};
  height: ${({ deactivate }) => (deactivate ? 80 : 45)}px;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  margin-bottom: 11px;
  ${weights['text-600']};
  background: ${colorsOptions.greys.white};

  &.addPin {
    cursor: ${({ newCategoryActive }) =>
      newCategoryActive ? 'default' : 'pointer'};

    &:hover {
      span.addPinButton {
        color: ${({ newCategoryActive }) =>
          newCategoryActive
            ? 'black'
            : colorsOptions.secondaryColors.orange.normal};
      }
    }
  }
`

export const Pin = styled.div`
  display: inline-block;
  margin-right: 10px;
  height: 45px;
  text-align: center;
  color: ${colorsOptions.greys['grey-50']};
  flex: 0 0 60px;
  span {
    ${sizing['text-xxxl']};
  }
`

export const NewCategoryInput = styled.input`
  display: inline-block;
  height: 41px;
  color: ${ifProp(
    'deactive',
    colorsOptions.greys['grey-50'],
    colorsOptions.greys['grey-100']
  )};
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  ${weights['text-600']};
`

export const CategoryOptions = styled.div`
  margin-left: auto;
  flex: 0 0 auto;
  span {
    input {
      vertical-align: middle;
    }
  }
  label {
    margin-right: 8px;
    display: inline-block;
    vertical-align: middle;
  }
`

export const PinColor = styled.div`
  margin-top: 5px;
  width: 60px;
  height: 32px;
  padding: 5px;
  text-align: center;
  border: 1px solid ${colorsOptions.greys['grey-20']};
  cursor: ${ifProp('active', 'pointer', 'default')};
  div {
    display: inline-block;
    width: 25px;
    height: 20px;
    margin-right: ${ifProp('active', '5', '19')}px;
    background-color: ${({ color }) => pinsColors[color]};
    border: 1px solid ${colorsOptions.greys['grey-10']};
  }
  img {
    margin-bottom: 9px;
  }
`

export const Clear = styled.button`
  color: ${colorsOptions.greys.white};
  background-color: ${({ disabled }) =>
    disabled ? colorsOptions.greys.white : colorsOptions.greys['grey-40']};
  border-radius: 20px;
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  height: 23px;
  right: 16px;
  text-align: center;
  top: 14px;
  width: 23px;
  img {
    display: inline-block;
    margin-top: -3px;
    vertical-align: middle;
  }

  &:hover {
    background-color: ${({ disabled }) =>
      disabled ? colorsOptions.greys.white : colorsOptions.greys['grey-50']};
  }
`

export const ActiveBox = styled.div`
  float: right;
  text-align: right;
  display: inline-block;
`

export const PickerWrapper = styled.div`
  position: relative;
`

export const Popup = styled(PopupLite)`
  top: 5%;
  width: 80%;
  left: 10%;
  height: 90%;
  @media (max-width: 767px) {
    left: 1%;
    width: 98%;
    height: 98%;
    top: 1%;
  }
`

export const CustomPickerDiv = styled.div`
  position: absolute;
  margin-top: -20px;
  text-align: left;
  padding: 5px;
  background-color: ${colorsOptions.greys.white};
  border: 1px solid ${colorsOptions.greys['grey-20']};
  z-index: 1;
`

export const ColorDivInPicker = styled.div`
  margin-bottom: 5px;
  height: 27px;
  cursor: pointer;
  padding-right: 5px;
  &:hover {
    background: ${applyOpacity(colorsOptions.greys['grey-50'], 0.5)};
  }
  border: 1px solid
    ${({ active }) =>
      active ? colorsOptions.greys['grey-50'] : colorsOptions.greys.white};
`

export const PinColorInPicker = styled.div`
  display: inline-block;
  float: left;
  width: 25px;
  height: 25px;
  margin-right: 5px;
  background-color: ${({ color }) => pinsColors[color]};
`

export const ColorTextInPicker = styled.div`
  color: ${colorsOptions.greys['grey-100']};
  display: inline-block;
  padding-top: 4px;
  span {
    ${sizing['text-l']};
  }
`

export const DefaultCategorySelector = styled.input`
  border: 1px solid ${colorsOptions.greys['grey-100']};
  &:checked {
    background: black;
  }
  border-radius: 10px;
`

export const DueDateWrapper = `
  position: absolute;
  right: 0px;
  bottom: 100%;
`

export const DateInfo = `
  padding: 0 13px;
`

export const ProjectReportTemplate = styled.div`
  display: flex;
  border-bottom: 1px solid ${colorsOptions.greys['grey-30']};

  img {
    display: flex;
    flex: 0 0 56px;
  }
  .content {
    display: flex;
    flex: 1;
    flex-direction: column;
    ${sizing['text-r']};
    padding-top: 11px;

    div:first-child {
      ${weights['text-600']};
      margin-bottom: 4px;

      & > span {
        ${weights['text-400']};
        color: ${colorsOptions.greys['grey-60']};
      }
    }
    div:last-child {
      ${weights['text-400']};
      ${sizing['text-r']};
      color: ${colorsOptions.greys['grey-60']};
    }
  }
  ${ifProp(
    'readOnly',
    `
    &:last-child {
      border-bottom: none;
    }
  `
  )}
`

export const ProjectReportTemplateRemoveWrapper = styled.div`
  padding-top: 0;
  display: flex;
  flex-direction: column;
  flex: 0 0 20px;
  justify-content: space-around;
  svg {
    display: flex;
    cursor: pointer;
  }
`

export const SelectReportTemplateWrapper = styled.div`
  display: flex;
  overflow: visible;
`

export const SelectReportTemplate = styled.div`
  flex: 0 0 100%;
  height: 56px;
`

export const AddEntryWrapper = styled.div`
  display: flex;
  flex: 0 0 100%;
  margin: 8px 8px 8px 0;
  height: 40px;
  color: ${colorsOptions.docuToolsOrange.orange};
  border-radius: 8px;
  cursor: pointer;
  &:hover {
    background: ${colorsOptions.greys['grey-20']};
  }

  div:first-child {
    display: flex;
    flex-direction: column;
    flex: 0 0 48px;
    text-align: center;
    justify-content: space-around;

    div {
      ${weights['text-400']};
      ${sizing['text-xxxl']};
    }
  }
  div:last-child {
    ${weights['text-600']};
    ${sizing['text-l']};
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: space-around;
  }
`

export const WeatherInfo = styled.div`
  ${sizing['text-r']};
  width: 90%;
  ${weights['text-700']};
  span:first-child {
    ${weights['text-500']};
  }
`

export const Loading = styled.div`
  color: ${colorsOptions.greys['grey-50']};
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const menuListStyle = {
  maxHeight: '300px',
  overflow: 'scroll',
  position: 'relative',
  backgroundColor: colorsOptions.greys.white
}

export const ContentFrame = styled.div`
  position: relative;
  background-color: ${colorsOptions.greys['grey-10']};
  border: 1px solid ${colorsOptions.greys['grey-30']};
  border-top: none;
  width: 100%;
  height: calc(100% - 40px);
`

export const SettingsFormGroup = styled.div`
  display: grid;
  grid-template-columns: 3fr 1fr;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  padding: 1em;
  //padding: 21px 24px 16px 24px;
`
export const SettingsFormVerticalGroup = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1 1 100%;
  & > :not(:first-child) {
    margin-top: 1em;
  }
`

export const TwoThirdsContainer = styled.div`
  grid-column-start: 1;
  grid-column-end: 2;
`

export const OneThirdContainer = styled.div`
  grid-column-start: 2;
  grid-column-end: 3;
  display: flex;
  align-items: center;
`

export const InputWithAfterIcon = styled.div`
  width: 100%;
  height: fit-content;
  position: relative;
  &::after {
    content: '';
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 16px;
    height: 16px;
    background-image: url('${prop('icon')}');
  }
`

export const SettingsFormSectionContainer = styled.div`
  flex: 1 1 100%;
`

export const SettingsFormSection = styled.div`
  border-radius: 8px;
  border: solid 1px ${colorsOptions.greys['grey-30']};
  width: 100%;
  background: ${colorsOptions.greys.white};
  padding: 1em;
  ${ifProp(
    'spaceBetween',
    css`
      display: flex;
      gap: 8px;
      justify-content: space-between;
      align-items: center;
      & > ${DatePickerControl} {
        flex: 1 1 auto;
      }
    `
  )}
`

export const ProjectAddressGrid = styled.div`
  display: grid;
  grid-template-columns: 3fr 1fr;
  grid-column-gap: 16px;
  padding: 21px 24px 16px 24px;
`

export const reportTemplateTableHeader = {
  backgroundColor: colorsOptions.greys['grey-10'],
  color: colorsOptions.greys['grey-50'],
  fontSize: '12px'
}

export const CategoriesGrid = styled.div`
  padding: 21px 4px 16px 0;
`

export const CategoryActivationControl = styled.img`
  margin-left: 16px;
  margin-top: 4px;
  cursor: pointer;
  opacity: ${ifProp('disabled', '.4', 1)};
  pointer-events: ${ifProp('disabled', 'none', 'all')};
`

export const CategoriesRow = styled.div`
  display: grid;
  grid-template-columns: 24px 58px auto 58px 72px;
  grid-column-gap: 8px;
  margin-bottom: 8px;
  margin-top: 8px;
  & > *:not(img) {
    opacity: ${ifProp('inactive', '.4', 'inherit')};
  }
  &.drag-over {
    border-top: 1px solid ${colorsOptions.greys['grey-100']};
  }
  &.drag-under {
    border-bottom: 1px solid ${colorsOptions.greys['grey-100']};
  }
  &.drag-over > *,
  &.drag-under > * {
    pointer-events: none;
  }
`

export const DraggableAnchor = styled.img`
  cursor: move;
`

export const DeactivateRadioWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: 8px;
  margin-bottom: 8px;
  margin-left: 16px;
`

export const OptionsBlock = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  margin: 0;
  padding: 10px;
  border-bottom: 1px solid ${colorsOptions.greys['grey-30']};
  @media (max-width: 767px) {
    margin: 0;
    padding: 6px 8px 2px;
  }
`
export const LeftBlock = styled.div`
  margin-right: auto;
  display: flex;
  align-items: center;
`
export const RightBlock = styled.div`
  margin-left: auto;
  flex: 0 0 auto;
  display: flex;
  align-items: center;
`
export const FilterButton = styled(Button)`
  width: ${ifProp('isClicked', '240px', '40px')};
  border-radius: ${ifProp('isDropdownClicked', '20px', '40px')};
  height: ${ifProp('isDropdownClicked', '200px', '40px')};
  transition: height 200ms, width 300ms;
  background: ${colorsOptions.greys.white};
  display: flex;
  margin-left: 16px;
  ${sizing['text-r']};
  ${weights['text-500']};
  z-index: 10;
  position: absolute;
  right: 3px;
  top: -20px;
  &:hover {
    background: ${colorsOptions.greys.white};
  }
  .select__filterCategory {
    width: 100%;
    padding: 3px 0;
    .Select__control--is-focused,
    .Select__control--is-focused:hover {
      border-color: transparent;
      box-shadow: none;
    }
  }
`
export const FieldsWrapper = styled.div`
  height: 100%;
  display: ${ifProp('collapse', 'flex', 'block')};
  justify-content: center;
`
export const FieldDetailsWrapper = styled.div`
  height: calc(100% - 50px);
  width: ${ifProp('active', '100%', 0)};
  transition: width 0.2s ease-out;
  border-left: ${ifProp(
    'active',
    `1px solid ${colorsOptions.greys['grey-30']}`
  )};
`
export const ListBlockWrapper = styled.div`
  transition: width 0.5s linear;
  height: auto;
  .itemDragging {
    left: 60px !important;
  }
  .list-content {
    padding-bottom: 2px;
  }
`
export const FieldProperty = styled.div`
  height: auto;
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  border-left: 1px solid
    ${ifProp('collapse', colorsOptions.greys['grey-30'], 'none')};
`
export const DirectionBlock = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -42%);
  width: 285px;
  height: auto;
  text-align: center;

  span {
    color: ${colorsOptions.greys['grey-50']};
    opacity: 1;
    align-self: center;
    ${sizing['text-r']};
    letter-spacing: 0;
    padding: 8px 0;
    display: block;
  }
`
export const ArrowImg = styled.img`
  width: 120px;
  padding: 7px 0;
  transform: translate(-30%, 0%);
`
export const FieldBlock = styled.div`
  display: grid;
  grid-template-columns: 34px auto auto;
  align-items: center;
  border: 1px solid
    ${ifProp(
      'active',
      colorsOptions.greys['grey-100'],
      colorsOptions.greys['grey-30']
    )};
  opacity: 1;
  background-color: ${colorsOptions.greys.white};
  padding: 0.5em;
  margin: 8px;
  border-radius: 4px;
  &:hover {
    box-shadow: 0 0 0.5px inset;
    cursor: pointer;
    border: 1px solid ${colorsOptions.greys['grey-50']};
  }
  & > svg {
    position: relative;
    top: 8px;
    opacity: 1;
    left: 7px;
  }
  & > img {
    margin: 0 8px;
  }
`
export const FieldLabel = styled.span`
  display: inline-block;
  ${sizing['text-r']};
  ${weights['text-500']};
  color: ${colorsOptions.greys['grey-100']};
`
const fieldInputStyle = css`
  ${sizing['text-r']};
  height: 32px;
  border: 1px solid
    ${ifProp(
      'disabled',
      colorsOptions.greys['grey-50'],
      colorsOptions.greys['grey-100']
    )};
  border-radius: 4px;
  padding: 4px 8px;
  margin-left: 0.5em;
  text-align: right;
  &:hover {
    opacity: 0.8;
  }
`
export const FieldInput = styled(Input)`
  ${fieldInputStyle};
  width: ${prop('width', '95px')};
  text-align: left;
`
export const NumberInput = styled(Input)`
  ${fieldInputStyle};
  width: ${prop('width', '95px')};
`
export const TitleFieldInput = styled(Input)`
  ${fieldInputStyle};
  width: 160px;
`
export const PinSet = styled.div`
  margin-right: auto;
  display: flex;
  align-items: center;
  margin-left: 8px;
  img {
    padding-right: 3px;
  }
`
export const CategoryCount = styled.div`
  margin-left: auto;
  flex: 0 0 auto;
  display: flex;
  align-items: center;
`
export const PropertyHeader = styled.div`
  display: flex;
  gap: 8px;
  ${sizing['text-r']};
  width: 100%;
  justify-content: space-between;
  padding: 8px 0 0 8px;
`
const placeBottomStyle = css`
  box-shadow: 0 3px 6px ${applyOpacity(colorsOptions.greys['grey-50'], 0.5)};
  border: 1px solid;
  border-radius: 8px;
  white-space: nowrap;
  padding: 4px 15px;
  &:after {
    border-left-color: transparent;
    border-bottom-style: hidden;
  }
`
export const ActionButtons = styled.div`
  ${sizing['text-r']};
  ${weights['text-600']};
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${colorsOptions.greys['grey-60']};
  margin-right: 8px;
  .react-tooltip {
    &__place-bottom {
      ${placeBottomStyle}
      &.deactivateToolTip {
        color: ${colorsOptions.secondaryColors.red.strong};
        background: ${colorsOptions.secondaryColors.red.pale};
        border-color: ${colorsOptions.secondaryColors.red.strong};
      }
      &.reactivateToolTip {
        color: ${colorsOptions.secondaryColors.darkgreen.strong};
        border-color: ${colorsOptions.secondaryColors.darkgreen.strong};
        background: ${colorsOptions.secondaryColors.darkgreen.pale};
      }
      &.infoToolTip {
        color: ${colorsOptions.secondaryColors.blue.strong};
        border-color: ${colorsOptions.secondaryColors.blue.strong};
        background: ${colorsOptions.secondaryColors.blue.pale};
      }
      &.placeholderToolTip {
        color: ${colorsOptions.secondaryColors.petrol.strong};
        border-color: ${colorsOptions.secondaryColors.petrol.strong};
        background: ${colorsOptions.secondaryColors.petrol.pale};
      }
    }
  }
`
export const DeactivateIcon = styled(Deactivate)`
  cursor: pointer;
  flex: 0 0 24px;
  color: ${colorsOptions.greys['grey-50']};

  &:hover {
    color: ${colorsOptions.secondaryColors.red.normal};
  }
`
export const InformationIcon = styled(InfoIcon)`
  cursor: pointer;
  flex: 0 0 24px;
  color: ${colorsOptions.greys['grey-50']};
`
export const PropertyDetails = styled.div`
  margin: 8px;
  padding-left: 8px;
  padding-right: 8px;
`
export const FieldFormControl = styled(FormControl)`
  ${ifProp(
    'isTag',
    css`
      padding: 8px 16px;
    `
  )}
  label {
    span {
      ${sizing['text-s']};
    }
  }
`
export const SelectOptionWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-left: 16px;
  padding: 2px 13px;
  ${weights['text-700']};
  ${sizing['text-r']};
  img {
    margin-right: 5px;
  }
`

export const DropdownIndicatorImg = styled.img`
  transform: rotate(${ifProp('isMenuOpen', '-90deg', '90deg')});
`

export const SelectedCategoryCount = styled.div`
  ${sizing['text-r']};
  height: 24px;
  width: 24px;
  padding: 3px 0;
  border: 1px solid ${prop('color', colorsOptions.greys['grey-60'])};
  text-align: center;
  vertical-align: bottom;
  color: ${prop('color', colorsOptions.greys['grey-60'])};
  border-radius: 4px;
  background-color: ${prop('bgColor', colorsOptions.greys.white)};
`
export const TitleInputs = styled(CustomInputs)`
  background-color: transparent;
  ${sizing['text-r']};
  border-color: ${ifProp(
    'isFocus',
    colorsOptions.docuToolsOrange.orange,
    colorsOptions.greys['grey-30']
  )};
`
export const SelectWrapper = styled.div`
  background: ${colorsOptions.greys.white};
  border: 1px solid ${colorsOptions.greys['grey-30']};
  border-radius: ${ifProp('isDropdownClicked', '8px 8px 0 0', '8px')};
  opacity: 1;
  padding: 8px 16px 8px 16px;
  height: ${ifProp('isDropdownClicked', '253px', 'auto')};
  transition: height 300ms;
  box-shadow: ${ifProp(
    'isDropdownClicked',
    '0 -1px 5px 0 rgb(20 26 33 / 20%)',
    'none'
  )};
`
export const SelectWrapperFooter = styled.div`
  ${sizing['text-xs']};
  padding: 8px 15px;
  background: ${colorsOptions.greys['grey-10']};
  border: solid ${colorsOptions.greys['grey-30']};
  border-width: 0 1px 1px 1px;
  border-radius: 0 0 8px 8px;
  opacity: 1;
  box-shadow: 0 1px 5px 0 rgb(20 26 33 / 20%);
  color: ${colorsOptions.greys['grey-60']};
`

export const PopOverStyle = styled.div`
  display: inline-block;
  ${sizing['text-s']};
  padding: 8px;
  margin: 0;
  position: absolute;
  top: 40px;
  left: auto;
  right: 0;
  box-shadow: 0 1px 5px 0 rgb(20 26 33 / 20%);
  z-index: 99;
  border-radius: 4px;
  color: ${colorsOptions.greys.white};
`
export const InfoPopOver = styled(PopOverStyle)`
  width: 215px;
  background: ${colorsOptions.secondaryColors.blue.normal};
  line-height: 1.6;
  & > span:not(:last-child) {
    display: inline-block;
    margin-bottom: 8px;
  }
`
export const PlaceholderPopOver = styled(PopOverStyle)`
  width: 260px;
  background: ${colorsOptions.secondaryColors.petrol.normal};
`
export const FieldDetailWrapper = styled.div`
  display: flex;
  position: relative;
  align-items: center;
  width: 100%;
  gap: 8px;
  & > span {
    flex: 1 1 auto;
  }
  padding: 4px 16px 4px 24px;

  .select__textFields {
    width: 30%;
  }
`
export const SliderDetailWrapper = styled(FieldDetailWrapper)`
  border-bottom: 1px solid ${colorsOptions.greys['grey-30']};
  padding: 16px 16px 8px 24px;
`
export const NumbersDetailWrapper = styled(FieldDetailWrapper)`
  .select__numberFormat {
    height: 32px;
    min-height: 0;
    cursor: pointer;
    box-shadow: none;
    width: 60%;
    ${sizing['text-r']};
    ${weights['text-600']};
    .Select__single-value {
      width: 100%;
    }
  }
  .Select__indicator-separator {
    width: 0;
  }
  .react-tooltip {
    &__place-bottom {
      ${placeBottomStyle}
      &.subScriptToolTip {
        color: ${colorsOptions.greys['grey-60']};
        border-color: ${colorsOptions.greys['grey-60']};
        background: ${colorsOptions.greys.white};
      }
    }
  }
`

export const LevelAndErrorWrapper = styled.div`
  display: flex;
  flex-direction: column;
  & > :nth-child(2) {
    margin-bottom: 8px;
  }
`
export const TitleLevelWrapper = styled.div`
  padding: 16px 16px 8px 24px;
`
export const LevelSet = styled.div`
  display: flex;
  position: relative;
  align-items: center;
  padding-bottom: 8px;
  width: 100%;
  gap: 8px;
  & > span {
    flex: 1 1 auto;
  }
`
export const PreviewWrapper = styled.div`
  background-color: ${colorsOptions.secondaryColors.petrol.pale};
  margin-top: auto;
  display: inline-block;
  box-sizing: border-box;
  width: 100%;
  padding: 10px 8px 20px 8px;
  & > div:first-of-type {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }
  & > div:last-of-type {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }
`
export const PreviewTitle = styled.span`
  display: inline-block;
  margin-bottom: 8px;
  letter-spacing: 0.06px;
  color: ${colorsOptions.secondaryColors.petrol.strong};
  text-transform: uppercase;
  ${sizing['text-s']};
  ${weights['text-700']};
`

export const AddFieldbtn = styled(Savebtn)`
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${colorsOptions.docuToolsOrange.orange};
  border-color: ${colorsOptions.docuToolsOrange.orange};
  color: ${colorsOptions.greys.white};
  ${sizing['text-r']};
  ${weights['text-700']};
  padding: 4px 8px;
  width: 170px;
  ${ifProp(
    'isOpen',
    css`
      pointer-events: none;
      background: ${colorsOptions.greys.white};
      color: ${colorsOptions.docuToolsOrange.orange};
      border-top: none;
      border-radius: 0 0 15px 15px;
    `
  )};
  transition: border-color 0.2s ease, background 0.2s ease, color 0.2s,
    border-radius 0.2s;

  &:hover {
    background: ${ifProp(
      'isOpen',
      colorsOptions.greys.white,
      colorsOptions.docuToolsOrange.orange
    )};
    border-color: ${colorsOptions.docuToolsOrange.orange};
    color: ${ifProp(
      'isOpen',
      colorsOptions.docuToolsOrange.orange,
      colorsOptions.greys.white
    )};
  }

  & > svg {
    flex: 0 0 auto;
    margin-left: -8px;
  }
`
export const DropDownMenu = styled.div`
  background: ${colorsOptions.greys.white};
  border: 1px solid ${colorsOptions.docuToolsOrange.orange};
  border-bottom: none;
  border-radius: 15px 15px 0 0;
  width: 100%;
  z-index: 10;
  min-width: 170px;
  position: absolute;
  opacity: 0;
  visibility: hidden;
  bottom: 40px;
  transform: translateY(20px);
  transition: opacity 0.2s ease, transform 0.4s ease, visibility 0.2s;

  &.active {
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
    box-shadow: none;
  }
  span {
    padding: 7px 12px;
    ${sizing['text-r']};
    display: block;
    width: 100%;
    ${weights['text-600']};
    @media (max-width: 767px) {
      padding: 15px 12px;
    }
  }

  div:first-child {
    border-radius: 15px 15px 0 0;
  }
`

export const ListItem = styled.div`
  display: flex;
  gap: 5px;
  ${sizing['text-r']};
  padding: 0 0.5em;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #e2dfdf;
  color: ${ifProp(
    'disabled',
    colorsOptions.greys['grey-50'],
    colorsOptions.greys['grey-100']
  )};
  cursor: ${ifProp('disabled', 'not-allowed', 'pointer')};
  pointer-events: ${ifProp('disabled', 'none', 'auto')};
  background: ${colorsOptions.greys.white};
  ${ifProp(
    'disabled',
    css`
      & > span {
        color: ${colorsOptions.greys['grey-50']};
      }
    `
  )}
`

export const ItemLabel = styled.span`
  color: ${ifProp(
    'disabled',
    colorsOptions.greys['grey-50'],
    colorsOptions.greys['grey-100']
  )};
`
export const FieldLabelGroup = styled.div`
  flex: 1 1 auto;
  ${sizing['text-r']};
  ${weights['text-600']};
  color: ${colorsOptions.greys['grey-100']};
  & > span {
    display: block;
  }
`
export const FieldSubLabel = styled.span`
  ${weights['text-500']};
  display: block;
  text-align: left;
  letter-spacing: 0.06px;
  ${sizing['text-s']};
  opacity: 1;
`
export const FieldSample = styled.div`
  ${sizing['text-r']};
  color: ${colorsOptions.greys['grey-60']};
  &:hover {
    & > sub {
      color: ${colorsOptions.docuToolsOrange.orange};
    }
  }
`

export const SelectedOption = styled.div`
  display: flex;
  width: 100%;
  .option-label {
    width: 90px;
    flex: 1 1 auto;
  }
  & > span {
    margin-left: 6px;
    color: ${colorsOptions.secondaryColors.blue.strong};
    text-align: right;
    width: 55px;
    margin-right: 3px;
  }
`
export const PlaceholderList = styled.div`
  min-height: 30px;
  display: flex;
  position: relative;
  align-items: center;
  padding: 0.5em;
  width: 100%;
  gap: 8px;
  & > span {
    ${weights['text-500']};
    flex: 1 1 auto;
    ${sizing['text-xs']};
    width: 30%;
  }
  & > label {
    ${weights['text-600']};
    width: 50%;
  }
`
export const SelectFilterOptionWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-left: 0;
  padding: 2px 10px 2px 0;
  ${weights['text-500']};
  ${sizing['text-r']};
  img,
  svg {
    margin-right: 5px;
  }
`
export const CategoryFilterWrapper = styled.div`
  position: relative;
`

export const TextPreviewStyles = `
  ${sizing['text-s']};
  ${weights['text-500']};
`
export const DetailWrapperBlock = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`
